<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="徽章系列绑定" prop="seriesId" >
        <a-select style="width: 100%" v-model="form.seriesId" placeholder="请选择徽章系列">
          <a-select-option v-for="(item, index) in this.seriesList" :value="item.id" :key="index">
            {{ item.seriesName }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="徽章名称" prop="badgeName" >
        <a-input v-model="form.badgeName" placeholder="请输入徽章名称" />
      </a-form-model-item>
      <a-form-model-item label="徽章等级" prop="badgeLevel" >
        <a-select style="width: 100%" v-model="form.badgeLevel" placeholder="请选择徽章等级">
          <a-select-option :value=1>Level.1</a-select-option>
          <a-select-option :value=2>Level.2</a-select-option>
          <a-select-option :value=3>Level.3</a-select-option>
          <a-select-option :value=4>Level.4</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="徽章图片" prop="badgeImage" >
<!--        <file-upload v-model="form.badgeImage" type="image"></file-upload>-->
        <oss-multi-upload
          v-model="form.badgeImage"
          type="img"
          bucket="badge"
          :maxCount="1"
          :isMultiple="false"
        ></oss-multi-upload>
      </a-form-model-item>
      <a-form-model-item label="状态 0.禁用 1.启用" prop="status" >
        <a-select style="width: 100%" v-model="form.status" placeholder="请选择徽章状态">
          <a-select-option :value=1>启用</a-select-option>
          <a-select-option :value=0>禁用</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="逻辑删除标记，1：删除，0：未删除，默认0" prop="isDeleted" v-if="formType === 1">
        <a-input v-model="form.isDeleted" placeholder="请输入逻辑删除标记，1：删除，0：未删除，默认0" />
      </a-form-model-item>
      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="乐观锁" prop="version" v-if="formType === 1">
        <a-input v-model="form.version" placeholder="请输入乐观锁" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getBadge, addBadge, updateBadge } from '@/api/badge/badge'
import { listBadgeSeries } from '@/api/badge/badgeSeries'
import OssMultiUpload from '@/views/script/script/modules/OssMultiUpload.vue'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    OssMultiUpload
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        seriesId: null,
        badgeName: null,
        badgeLevel: null,
        badgeImage: null,
        status: null,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        badgeName: [
          { required: true, message: '徽章名称不能为空', trigger: 'blur' }
        ],
        badgeLevel: [
          { required: true, message: '徽章等级不能为空', trigger: 'change' }
        ],
        badgeImage: [
          { required: true, message: '徽章图片不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '状态 0.禁用 1.启用不能为空', trigger: 'change' }
        ],
        isDeleted: [
          { required: true, message: '逻辑删除标记，1：删除，0：未删除，默认0不能为空', trigger: 'blur' }
        ],
        version: [
          { required: true, message: '乐观锁不能为空', trigger: 'blur' }
        ]
      },
      seriesList: []
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
    this.getSeriesList()
  },
  methods: {
    getSeriesList() {
      listBadgeSeries().then(res => {
        this.seriesList = res.data.records
      })
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        seriesId: null,
        badgeName: null,
        badgeLevel: null,
        badgeImage: null,
        status: null,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getBadge(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateBadge(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addBadge(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
